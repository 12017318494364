import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import { FormField } from './FormField';

const Home = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);

    const navigate = useNavigate();

    // For enabling the Submit button once user has entered all the required details.
    useEffect(() => {
        if (isFirstNameValid && isLastNameValid && isEmailValid) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [isFirstNameValid, isLastNameValid, isEmailValid]);

    // handling submit button which relays the user information to next page.
    const handleSubmit = (e) => {
        if (isFormValid) {
            navigate('/task', {
                state: {
                    username: firstName + " " + lastName,
                    email: email
                }
            });
        }
    };

    // handling that first name should only contain alphabets.
    const handleFirstNameChange = (e) => {
        const nameValue = e.target.value;
        const nameRegex = /^[A-Za-z]+$/;
        setFirstName(nameValue);
        setIsFirstNameValid(nameRegex.test(nameValue));
    };
    
    // handling that last name should only contain alphabets.
    const handleLastNameChange = (e) => {
        const nameValue = e.target.value;
        const nameRegex = /^[A-Za-z]+$/;
        setLastName(nameValue);
        setIsLastNameValid(nameRegex.test(nameValue));
    };

    // handling that email should only contain alphabets.
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(emailValue)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    };

    return (
        <div className="home">
            <h1>Take 2 AI Candidate Details</h1>
                <form action="#" method="get" onSubmit={handleSubmit}>
                <FormField
                    labelName = "First Name"
                    inputType = "text"
                    inputId = "firstname"
                    inputValue = {firstName}
                    onChangeFunc = {handleFirstNameChange}
                    placeholder = "Enter First Name"
                />
                <FormField
                    labelName = "Last Name"
                    inputType = "text"
                    inputId = "lastname"
                    inputValue = {lastName}
                    onChangeFunc = {handleLastNameChange}
                    placeholder = "Enter Last Name"
                />
                <FormField
                    labelName = "Enter Email"
                    inputType = "email"
                    inputId = "email"
                    inputValue = {email}
                    onChangeFunc = {handleEmailChange}
                    placeholder = "Enter Email"
                />
                <button
                    type="submit"
                    value="Submit"
                    disabled={!isFormValid}
                    className='submit-button'
                >
                    Submit
                </button>
            </form>
      </div>
    );
}

export default Home;