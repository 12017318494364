import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Results.css';

const Results = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const communicationScore = location.state?.communicationScore;
    const communicationFeedback = location.state?.communicationFeedback;
    
    // Based on results of the evaluation, we have to make a decision to either show the results page or not.
    useEffect(() => {
        if (communicationScore === undefined || communicationFeedback === undefined) {
            restartProcess();
        }
    }, [communicationScore, communicationFeedback]);

    // User is given the option to restart the process. Maybe they would get another task to complete.
    const restartProcess = () => {
        navigate('/');
    };

    return (
        <div className='results'>
            <h1>Evaluation Result</h1>
            {
                communicationScore !== undefined && (
                <p>Final Score: {communicationScore}</p>
                )
            }
            {
                communicationFeedback !== undefined && (
                <p>Evaluation: {communicationFeedback}</p>
                )
            }
            <button onClick={restartProcess} className='restart-button'>
                Want to Try Again?
            </button>
        </div>
    );
}

export default Results;