import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Task from './components/Task/Task';
import Results from './components/Results/Results';

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/task" element={<Task/>} />
            <Route path="/results" element={<Results/>} />
        </Routes>
    </Router>
    );
}

export default App;
