import { React } from 'react';

export const FormField = ({
    labelName,
    inputType,
    inputId,
    inputValue,
    onChangeFunc,
    placeholder
}) => {
    return (
        <>
            <label htmlFor={inputId}>
                {labelName}*
                <input
                    type={inputType}
                    name={inputId}
                    id={inputId}
                    value={inputValue}
                    onChange={onChangeFunc}
                    placeholder={placeholder}
                    required
                />
            </label>
        </>
    );
}