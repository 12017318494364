// from take2: sk-proj-NsBcCpH3QgLD5QbyWZBrT3BlbkFJfRS7N9qJVKIywlYehOfC

import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_REGION, S3_BUCKET_NAME, OPENAI_API_KEY } from '../../config';
import React, { useState, useEffect }from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MicRecorder from 'mic-recorder-to-mp3';
import axios from 'axios';
import AWS from 'aws-sdk';
import Loader from '../Loader/Loader';
import './Task.css';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Task = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state?.username;
    const email = location.state?.email;

    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioFile] = useState(null);
    const [blobURL, setBlobURL] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);
    const [transcription, setTranscription] = useState(null);
    const [communicationScore, setCommunicationScore] = useState(null);
    const [communicationFeedback, setCommunicationFeedback] = useState(null);
    const [s3AudioUrl, setS3AudioUrl] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [taskId, setTaskId] = useState(-1);
    const [taskPrompt, setTaskPrompt] = useState(null);

    // This happens as the page is opened. We do some checks for some the data from previous page.
    // Also, we setup AWS connection so, that when user submits the recording, that gets uploaded to S3.
    useEffect(() => {
        if (username === undefined || email === undefined) {
            navigate('/');
        }

        setProcessing(true);

        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
            console.log('Permission Granted');
            setIsBlocked(false);
        })
        .catch(() => {
            console.log('Permission Denied');
            setIsBlocked(true);
        });

        getTask();

        AWS.config.update({
            accessKeyId: AWS_ACCESS_KEY_ID,
            secretAccessKey: AWS_SECRET_ACCESS_KEY,
            region: AWS_REGION,
        });

        setProcessing(false);

    }, []);

    // Based on all the details gathered regarding the recording, we send all the information to RDS and navigate to results page.
    useEffect(() => {
        if (s3AudioUrl !== null && 
            transcription !== null && 
            communicationScore !== null &&
            communicationFeedback !== null && 
            taskId !== -1
        ) {
            saveAudioDetails();
            setProcessing(false);

            navigate('/results', {
                state: {
                    communicationScore: communicationScore,
                    communicationFeedback: communicationFeedback
                }
            });
        }
        
    }, [transcription, communicationScore, communicationFeedback, s3AudioUrl, taskId]);

    const startRecording = () => {
        if (isBlocked) {
            console.log('Permission Denied');
        } else {
            Mp3Recorder.start()
                .then(() => {
                setIsRecording(true);
                }).catch((e) => console.error(e));
        }
    };

    const stopRecording = () => {
        Mp3Recorder.stop().getMp3().then(([buffer, blob]) => {
            const blobURL = URL.createObjectURL(blob);
            setBlobURL(blobURL);
            setIsRecording(false);
            setAudioFile(blob);
        }).catch((e) => console.log(e));
    };

    // This transcribes the audio response and get an associated score and feedback from OpenAI API.
    const transcribeAndGetTaskScore = async () => {
        setProcessing(true);
        
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.mp3');
        formData.append('model', 'whisper-1'); 

        try {
            // Step 1: Transcribe audio
            const transcriptionResponse = await axios.post(
                'https://api.openai.com/v1/audio/transcriptions',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                    },
                }
            );

            const transcription = transcriptionResponse.data.text;
            setTranscription(transcription);

            // Step 2: Evaluate communication criteria
            const evaluationResponse = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: "gpt-3.5-turbo", 
                    messages: [
                        { 
                            role: "user", 
                            content: `Give a score from 1-10 and feedback for the following answer 
                            for the question: "${taskPrompt}", 
                            based on communication criteria and grammatical correctness indicator :\n\n"${transcription}"`  
                        }
                    ],
                    max_tokens: 256,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                        'Content-Type': 'application/json'
                    },
                }
            );

            const scoreAndFeedback = evaluationResponse.data.choices[0].message.content.trim();
            const regex = /Score:\s*(.*?)\s*Feedback:\s*(.*)/;
            const match = scoreAndFeedback.match(regex);

            if (match) {
                const communicationScore = match[1];
                const communicationFeedback = match[2];
                setCommunicationScore(communicationScore);
                setCommunicationFeedback(communicationFeedback);
                uploadAudioToS3();
            }

        } catch (error) {
            console.error('Error processing audio:', error);
            console.error('Response data:', error.response?.data);
        }
    };

    // We upload audio file to S3 when submit button is clicked.
    const uploadAudioToS3 = () => {
        const s3 = new AWS.S3();
        const params = {
            Bucket: S3_BUCKET_NAME,
            Key: `audio-${Date.now()}.mp3`,
            Body: audioBlob,
            ContentType: 'audio/mpeg',
        };
    
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading audio to S3:', err);
            } else {
                console.log('Successfully uploaded audio to S3:', data.Location);
                setS3AudioUrl(data.Location);
            }
        });
    };

    // We get a new randomized task from tasks table in RDS.
    const getTask = () => {
        axios.post('http://34.229.213.243:3001/api/get-task')
        .then(response => {
            console.log('Successfully got task details:', response.data);
            setTaskId(response.data.id);
            setTaskPrompt(response.data.task_prompt);
        })
        .catch(error => {
            console.error('Error saving audio details:', error);
        });
    }

    // we save all the audio and user meta data.
    const saveAudioDetails = () => {
        axios.post('http://34.229.213.243:3001/api/add-audio', {
            username, 
            email, 
            taskId, 
            transcription, 
            communicationScore, 
            communicationFeedback, 
            s3AudioUrl
        })
        .then(response => {
            console.log('Successfully saved audio details:', response.data);
        })
        .catch(error => {
            console.error('Error saving audio details:', error);
        });
    };
    
    const handleTaskSubmission = () => {
        transcribeAndGetTaskScore();
    };

    return (
        <div className = "record">
            { processing && <Loader /> }
            <header className="App-header">
                {taskPrompt && <h1>{taskPrompt}</h1>}
                <div>
                    <button onClick={startRecording} disabled={isRecording || processing} className='record-button'>Start</button>
                    <button onClick={stopRecording} disabled={!isRecording || processing} className='record-button'>Stop</button>
                    {blobURL && <audio src={blobURL} controls />}
                </div>
            </header>

            {
                blobURL &&
                <button
                    type="submit"
                    value="Submit"
                    onClick={handleTaskSubmission}
                    disabled={processing}
                    className='record-button'
                >
                    {processing ? 'Processing...' : 'Submit'}
                </button>
            } 
        </div>  
    ); 
}

export default Task;