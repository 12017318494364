import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import './Loader.css';

const Loader = () => {
    return (
        <div className="loader-overlay">
            <div className="loader-container">
            <TailSpin color="#643b91a8" height={80} width={80} />
            </div>
        </div>
    );
};

export default Loader;
